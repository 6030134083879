// Spacing
$spacing-none: 0;
$spacing-xxsmall: 2px;
$spacing-xsmall: 4px;
$spacing-small: 8px;
$spacing-medium: 16px;
$spacing-large: 24px;
$spacing-xlarge: 32px;
$spacing-xxlarge: 48px;
$spacing-xxxlarge: 76px;

// Font sizes
$font-size-xxsmall: 10px;
$font-size-xsmall: 12px;
$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-xlarge: 20px;
$font-size-xxlarge: 24px;
$font-size-xxxlarge: 32px;
$font-size-4xlarge: 40px;

$font-line-height-medium: 45px;

// Breakpoints
$screen-xs: 575px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;

// Font weight
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

$border-radius-xsmall: 4px;
$border-radius-medium: 16px;

$sider-width: 300px;
$sider-width-collapsed: 80px;

$page-header-height: 88px;

$shadow-length-small: 1px;
$header-height: 64px;
$header-height-with-shadow: calc(#{$shadow-length-small} + #{$header-height});

$search-max-width: 360px;

$footer-height: 70px;
