@import "src/ui/colors";
@import "src/ui/variables";

.header {
  position: fixed;
  top: 1px;
  right: 0;
  left: 0;
  background-color: $white-color;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: none;
  margin-bottom: 1px;
  z-index: 9;
  padding: 0 $spacing-medium;
  margin-left: $sider-width-collapsed;
  transition: all 0.2s;

  &--collapsed {
    margin-left: $sider-width;
  }

  .headerUserName {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    font-weight: $font-weight-700;
    text-align: center;
    margin-right: $spacing-large;
    :hover {
      opacity: 0.7;
    }
    .userIcon {
      margin-right: $spacing-xsmall;
      position: relative;
      top: -1px;
    }
  }

  .signOutButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    .signOutButtonText {
      color: $black-dark-text-color;
      padding: $spacing-none $spacing-xsmall;
      font-size: $font-size-small;
      font-weight: $font-weight-500;
    }
    .signOutButtonIcon {
      color: $black-dark-text-color;
      font-size: $font-size-xsmall;
    }
  }
}
