@import "src/ui/colors";
@import "src/ui/variables";

.wrapper {
  & + & .content {
    padding-top: 0;
  }
}

.content {
  padding: $spacing-medium;
  padding-top: $page-header-height;
}
