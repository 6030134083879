@import "src/ui/colors";
@import "src/ui/variables";

.contentWrapper {
  margin-top: calc(#{$header-height-with-shadow});
  margin-left: calc(#{$sider-width});
  transition: all 0.2s ease;
  min-height: calc(
    100vh -
      (
        #{$header-height-with-shadow} + #{$page-header-height} + #{$footer-height} +
          #{$spacing-medium}
      )
  );
}

.collapsedWidth {
  margin-left: calc(#{$sider-width-collapsed});
}

// .contentWrapper {
//   margin: $spacing-medium;
//   margin-top: calc(
//     #{$header-height-with-shadow} + #{$page-header-height} + #{$spacing-medium}
//   );
//   margin-left: calc(#{$sider-width} + #{$spacing-medium});
//   transition: all 0.2s ease;
//   // screen height minus (headers + footer + margins height)
//   min-height: calc(
//     100vh -
//       (
//         #{$header-height-with-shadow} + #{$page-header-height} + #{$spacing-medium} +
//           #{$footer-height} + #{$spacing-medium}
//       )
//   );
//   background: $white-color;
//   padding: $spacing-medium;
// }

// .collapsedWidth {
//   margin-left: calc(#{$sider-width-collapsed} + #{$spacing-medium});
//   margin-right: $spacing-medium;
// }
