@import "src/ui/colors";
@import "src/ui/variables";

.pageHeader {
  position: fixed;
  width: 100%;
  background-color: $white-color;
  transition: all 0.2s ease;
  z-index: 1;
  height: 70px;
}

.collapsedWidth {
  // margin-left: $sider-width-collapsed;
}
