@import "src/ui/colors";
@import "src/ui/variables";

.primary {
  padding: 15px !important;
  margin: $spacing-large 0;
  border: none !important;
  border-radius: $border-radius-medium;
  background: $white-color;
}
