@import "src/ui/colors";
@import "src/ui/variables";

.logo {
  max-width: 140px;
  margin: 16px 20px 0px 20px;
}

.sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 2;
  width: $sider-width;
  // background-color: rgb(72, 69, 196);
  background-color: #F0F1F5;
  border: none;
}

.menu {
  border: none;
  margin-top: 16px;
  background-color: transparent;
}
.active{
  color: #3D4BB5 !important;
}
