$black-color: #000000;
$black-text-color: #262626;
$black-light-text-color: rgba(0, 0, 0, 0.65);
$black-dark-text-color: #595959;

$white-color: #ffffff;
$white-transparent-color: rgba(255, 255, 255, 0.2);

$grey-light: #e9e9e9;

$primary-color: #000646;
