@import "src/ui/variables";

.table {
  :global {
    .ant-table-cell {
      max-width: 100px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
