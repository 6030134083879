@import "src/ui/colors";
@import "src/ui/variables";

.wrapper {
  background-color: rgb(246, 247, 248);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.container {
  max-width: 330px;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: $spacing-xlarge;
}

.logo {
  width: 150px;
}
