@import "src/ui/colors";
@import "src/ui/variables";

.itemDataWrapper {
  display: flex !important;
  align-items: flex-end !important;

  .influencerImage {
    border-radius: 50%;
  }
  .influencerName {
    margin-left: $spacing-medium;
  }
}

.dropStatusHeader {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 23px;
}
