.detailContainer {
  background: #ffffff;
  padding: 0 30px 0 40px;
  // min-height: 100vh;
  height: 100%;
  &.spinCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  .link {
    padding: 6px 10px;
    background: #f0f1f5;
    border-radius: 4px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: #535567;
    &:hover {
      background: #dfe2f6;
      color: #2d40d1;
    }
  }
  .active {
    background: #dfe2f6;
    color: #2d40d1;
    padding: 6px 10px;
    border-radius: 4px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
  }
  .linkWrapper {
    gap: 10px;
    display: flex;
    margin-bottom: 32px;
  }
  .backBtn {
    box-shadow: none;
    border: none;
    padding: 0;
    font-size: 14px;
    margin-bottom: 20px;
    display: inline-block;
    svg {
      margin-right: 5px;
      path {
        fill: #10111c;
      }
    }
  }
}

.block {
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  padding: 16px;

  .infoBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.changeRoleWrapper {
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
}
.selectRole {
  margin-left: 20px;
}

.linkRedirect {
  cursor: pointer;
  color: #3d4bb5;
  text-decoration: underline;
  transition: all 0.3s;
  &:hover {
    opacity: 0.7;
  }
}

.handleButton {
  margin-left: 10px;
}

.deleteButton {
  margin-left: 10px;
  margin-top: 10px;
}

.disabled {
  pointer-events: none;
  color: #dfe2f6;
  cursor: not-allowed;
}
