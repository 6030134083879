.detailContainer {
  background: #ffffff;
  padding: 0 30px 0 40px;
  // min-height: 100vh;
  height: 100%;
  &.spinCenter{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  .link {
    padding: 6px 10px;
    background: #f0f1f5;
    border-radius: 4px;
  
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
      color: #535567;
      &:hover{
        background: #DFE2F6;
        color: #2D40D1;
      }
    }
    .active{
      background: #DFE2F6;
      color: #2D40D1;
      padding: 6px 10px;
      border-radius: 4px;
    
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
    }
  .linkWrapper{
    gap: 10px;
    display: flex;
    margin-bottom: 32px;
  }
  .backBtn{
    box-shadow: none;
    border: none;
    padding: 0;
    font-size: 14px;
    margin-bottom: 20px;
    display: inline-block;
    svg{
      margin-right: 5px;
      path{
        fill: #10111C;
      }
    }
  }
}


.block{
    border: 1px solid #F3F3F3;
    border-radius: 4px;
    padding: 16px;

    .infoBlock{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
}


.changeRoleWrapper {
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
}
.selectRole{
  margin-left: 20px;
}

.linkRedirect{
  cursor: pointer;
  color: #3D4BB5;
  text-decoration: underline;
  transition: all .3s;
  &:hover{
    opacity: .7;
  }
}

.handleButton {
  margin-left: 10px;
}

.disabled {
  pointer-events: none;
  color: #DFE2F6;
  cursor: not-allowed;
}