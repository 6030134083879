@import "src/ui/colors";
@import "src/ui/variables";

.inputBasic {
  border-radius: $border-radius-xsmall;
}

.inputNumber {
  padding-top: 6px;
  min-height: 52px;
  border-radius: 16px;
  // padding: 15px, 16px, 15px, 16px;
}

.inputPassword {
  border-radius: $border-radius-xsmall;
}

.inputTextarea {
  border-radius: $border-radius-xsmall;
  min-height: 100px !important;
}

.inputSelect {
  :global(.ant-select-selector) {
    border-radius: $border-radius-xsmall !important;
  }
}
