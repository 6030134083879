@import "src/ui/colors";
@import "src/ui/variables";

.drawer {
    .content {
      display: block;
    }
  
    .spinner {
      display: none;
    }
  }
  
  .drawer.drawerLoading {
    :global {
      .ant-drawer-body {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  
    .content {
      display: none;
    }
  
    .spinner {
      display: block;
    }
  }
  